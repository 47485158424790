body {
  border: solid 15px #fff;
}

.container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}

.cta-block {
  width: 100%;
  margin: 0 auto;
  padding: 30px 20%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #e3e3e3;
  @extend .cf;

  .video-intro__profile {
    float: left;
    width: 20%;
    text-align: center;
    @media (max-width: 600px) {
        width: 100%;
        margin-bottom: 20px;
    }
  }
  img {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border: solid 4px #ddd;
  }
}
// TYPE
.heading {
}
.heading--feature {
  font-family: "Abel";
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 28px;
  display: inline-block;
  margin-top: 0;
  @media (max-width: 600px) {
    font-size: 24px;
  }
}
.heading--code {
  font-family: "source-code-pro", "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
  font-size: 18px;
  color: (255,255,255,0.9);
}

h2.heading--code {
  font-weight: 300;
}
.content--code {
  font-family: "source-code-pro", "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
  margin-top: 0;
  @media (max-width: 600px) {
    line-height: 1.3;
  }
}

// DETAILS
svg.play {
    height: 125px;
    width: 125px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    cursor: pointer;

    polyline {
      stroke: rgba(255,255,255,0.7);
      fill: transparent;
      stroke-width: 2px;
    }
    polygon {
      fill: rgba(255,255,255,0.7);
    }
    .inner-ring {
      transform-origin: 50% 50%;
      transition: all 120ms ease-in;
    }
    .outer-ring {
      transform-origin: 50% 50%;
      transition: all 150ms ease-in;
      opacity: 1;
    }
    .outer-ring-2 {
      transform-origin: 50% 50%;
      transition: all 135ms ease-in;
      opacity: 1;
    }

    &:hover {
      .inner-ring {
        transform: scale(0.875);
      }
      .outer-ring {
        transform: scale(0.5);
        opacity: 0;
      }
      .outer-ring-2 {
        transform: scale(0.6) ;
        opacity: 0;
      }
    }
}

div.play--tr {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  cursor: pointer;
}
// DETAILS
svg.play--tr {
  margin: 8px;
  height: 13px;
  opacity: 0.65;
  width: auto;
  animation: 2.8s shake infinite alternate;
}

svg.play--tr--delay {
  animation-delay: 1.8s;
}

@keyframes shake {
  0% { transform: skewX(-4deg) translateX(0px) translateY(0px); }
  5% { transform: skewX(4deg) translateX(1px) translateY(0px); ; }
  6% { transform: skewX(0deg) translateX(0px) translateY(0px); }
  50% { transform: skewX(0deg) translateX(0px) translateY(0px);  }
  55% { transform: skewX(4deg) translateX(1px) translateY(0px);  }
  56% { transform: skewX(0deg) translateX(0px) translateY(0px);  }
  66% { transform: skewX(-4deg) translateX(0px) translateY(0px);  }
  67% { transform: skewX(0deg) translateX(0px) translateY(0px);  }
  100% { transform: skewX(0deg) translateX(0px) translateY(0px);  }  
}



svg.detail {
  height: 200px;
  width: 175px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(29%, -82%);
  cursor: pointer;

  line {
      stroke: rgba(255,255,255,0.7);
      stroke-width: 0.5px;
  }
}

// BUTTONS


a.button--corners {
  display: inline-block;
  padding: 12px 24px;
  margin: 0 20px 0 0 ;
  font-family: "source-code-pro", "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  color: #fff;
  position: relative;
  cursor: pointer;

  &:link,
  &:visited {
    color: #fff;
  }
  &:hover {
    color: #fff;
    background-color: rgba(255,255,255,0.3);
    .box-corner--tl {
      transform: translate(-5px, -5px);
    }
    .box-corner--tr {
      transform: translate(5px, -5px) rotate(90deg);
    }
    .box-corner--br {
      transform: translate(5px, 5px) rotate(180deg);
    }
    .box-corner--bl {
      transform: translate(-5px, 5px) rotate(-90deg);
    }
  }
  .box-corner {
    width: 12px;
    height: 12px;
    position: absolute;
    transition: all ease-in 120ms;

    polyline {
      stroke: #ffffff;
      fill: transparent;
      stroke-width: 7px;
    }
  }
  .box-corner--tl {
    top: 5px;
    left: 5px;
  }
  .box-corner--tr {
    top: 5px;
    right: 5px;
    transform: rotate(90deg);
  }
  .box-corner--br {
    bottom: 5px;
    right: 5px;
    transform: rotate(180deg);
  }
  .box-corner--bl {
    bottom: 5px;
    left: 5px;
    transform: rotate(-90deg);

  }
  &:hover,
  &:visited {
    color: #fff;
  }
}

.instagram-row {
  display: flex;

  iframe {
    flex-direction: row;
  }
}

.efImageLink {
  background-size: cover;
    background-position: center center;
}
