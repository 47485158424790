.single--video__background {
  background-position: center center;
  background-size: cover;
  position: relative;
}
.single--video__container {
  padding: 120px 0 60px;
  max-width: 880px;
  margin: 0 auto;

  @media (max-width: 1060px) {
    padding: 90px 0 60px;
  }
  @media (max-width: 1000px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 600px) {
    padding: 120px 15px 15px;
  }
}
.single--video__frame {
  width: 100%;
  box-shadow: 0 0 10px rgba(0,0,0,0.4);
}

.behind-the-video-title {
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 22px;
    margin-top: 0;
  }
}

.behind-the-video-meta {
    @extend .cf;
    margin-top: 10px;
    padding: 10px 25px;
    background-color: #f1f1f1;
    font-style: italic;
    color: #737373;
    line-height: 20px;
    text-align: right;
    line-height: 45px;

    @media (max-width: 600px) {
      line-height: 25px;
    }
    .social-links--content-end {
      @media (max-width: 600px) {
        width: 100%;
        text-align: center;
        line-height: 30px;
      }
    }
    .social-links--content-end .share-title{
      margin-right: 10px;
      @media (max-width: 600px) {
        display: block;
        width: 100%;
      }
    }
    .social-icon {
      margin-left: 5px;
      @media (max-width: 600px) {
        margin: 0 5px;
        width: 20px;
        height: 20px;
      }
    }
}
.behind-the-video-meta__links {
  text-align: center;
  position: static;
  padding-top: 10px;
  @media (min-width: 600px) {
    padding-top: 0;
    position: relative;
    float: left;
    width: 30%;
    text-align: left;
  }
  > div {
    @media (min-width: 600px) {
      position: absolute;
      top: 50%;
      transform: translateY(50%);
    }
  }
}
.behind-the-video-meta__info {
  float: right;
  width: 70%;
  @media (max-width: 600px) {
    width: 100%;
  }
}

.scroll-down__cta {
  color: rgba(255,255,255,0.8);
  text-align: center;
}

.page-navigation {
  @include clearfix;
}
.prev-post,
.next-post {
  padding: 10px 0 0 0;
  @include span-columns(6);
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  a {
    color: #6f6f6f;
  }
}
.next-post {
  text-align: right;
}
