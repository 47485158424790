
@import "neat/neat-helpers";

// Change the grid settings
$column: 90px;
$gutter: 20px;
$grid-columns: 12;
$max-width: em(1088);

// Define your breakpoints
$tablet: max-width 810px;
$mobile: max-width 400px;