.nav-container {
  width: 100%;
  position: relative;
}
a.nav-logo {
  text-transform: uppercase;
  font-family: "Abel", Arial, sans-serif;
  font-size: 44px;
  letter-spacing: 2px;
  color: #fff;
  float: left;
  a:link,
  &:hover,
  &:visited:hover,
  &:visited {
    color: #fff;
  }
  @media (max-width: 1050px) {
    font-size: 32px;
  }
  @media (max-width: 600px) {
    width: 100%;
    text-align: center;
    display: block;
    font-size: 26px;
  }
}

nav {
  margin-left: 50px;
  float: left;
  @media (max-width: 600px) {
    width: 100%;
    text-align: center;
    display: block;
    float: none;
  }
  a {
    line-height: 66px;
    margin: 0 20px;
    font-size: 22px;
    text-transform: uppercase;
    font-family: "Abel", Arial, sans-serif;
    letter-spacing: 1px;
    color: #fff;

    @media (max-width: 1050px) {
      font-size: 17px;
      line-height: 48px;
      margin: 0 15px;
    }
    @media (max-width: 600px) {
        line-height: 30px;
        margin: 0 5px;
        font-size: 16px;
    }

    a:link,
    &:hover,
    &:visited:hover,
    &:visited {
      color: #fff;
    }
  }
  @media (max-width: 1050px) {
    margin-left: 25px;
  }
  @media (max-width: 600px) {
    margin-left: 0;
  }
}
.nav-block {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  padding-top: 25px;
}

.social-icons {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 75px;
  vertical-align: middle;
  padding-right: 10px;
  a {
    margin: 20px 5px 0;
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
  }
  @media (max-width: 1050px) {
    a {
      margin: 10px 0 0;
    }
  }
  @media (max-width: 800px) {
    display: none;
  }
  @media (max-width: 600px) {
  }
}

.nav__icon {
  display: block;
  stroke: #fff;
  height: 30px;
  width: 30px;
  cursor: pointer;
  @media (max-width: 1050px) {
    height: 25px;
    width: 25px;
  }
}

.nav__icon--facebook {
  fill: #fff;
}
.nav__icon--facebook-dark {
  fill: #111;
}

.nav__icon--youtube {
  transform: translateY(-3px);
 polygon {
   fill: #fff;
 }
 path {
   fill: transparent;
   stroke: #fff;
   stroke-width: 5px;
 }
}

.nav__icon--instagram {
  circle, rect {
    fill: transparent;
    stroke: #fff;
    stroke-width: 5px;
  }
}

.nav__icon--twitter {
  transform: translateY(-3px);
  height: 40px;
  @media (max-width: 1050px) {
    transform: translateY(-6px);
  }
  path {
    fill: transparent;
    stroke: #fff;
    stroke-width: 5px;
  }
}
.nav__icon--twitter-dark {
  transform: translateY(5px);

  path {
    stroke: #111;
    fill: #111;
    stroke-width: 3px;
  }
}

.nav__icon--mail {
  fill: #111111;
}
