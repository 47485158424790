.videos-page {
  // max-width: 920px;
  margin: 0 auto;
  // padding-top: 40px;

  // @media (max-width: 1000px) {
    // padding: 5%;
  // }
}

.g-ytsubscribe {
  min-height: 31px;
}

.videos-intro {
  width: 70%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 40px;
  @extend .cf;

  @media (max-width: 800px) {
        width: 100%;
  }
  @media (max-width: 600px) {
        flex-direction: column;
  }


  p {
    // font-family: "ff-tisa-web-pro", sans-serif;
    // font-size: 20px;
    // margin: 0 0 0 20px;
    // float: left;
    // width: 80%;
    //
    // @media (max-width: 600px) {
    //       width: 100%;
    // }
    // a {
    //   font-style: italic;
    //   position: relative;
    //   z-index: 10;
    //   &:before {
    //     content: "";
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     width: 0%;
    //     min-height: 85%;
    //     background: rgba(0,0,0, 0.9);
    //     z-index: -1;
    //     transition: 300ms width ease;
    //   }
    //   &:hover {
    //     color: #fff;
    //     &:before {
    //       width: 100%;
    //     }
    //   }
    // }
  }
  // .video-intro__profile {
  //   float: left;
  //   width: 20%;
  //   text-align: center;
  //   @media (max-width: 600px) {
  //       width: 100%;
  //       margin-bottom: 20px;
  //   }
  // }
  // img {
  //   width: 100px;
  //   height: 100px;
  //   border-radius: 100%;
  //   border: solid 4px #e5e5e5;
  // }
}

// .popular {
//   position: relative;
//   display: flex;
//
//   @media (max-width: 600px) {
//       flex-flow: row wrap;
//   }
// }
// .feature__titles {
//   margin-top: 5px;
//   margin-bottom: 5px
// }
// .feature__readmore{
//   display: inline-block;
//   float: right;
//   margin: 1em 0 ;
// }
// .feature__readmore-icon {
//   display: inline-block;
//   height: 20px;
//   width: 20px;
//   vertical-align: middle;
//   margin-bottom: 3px;
//   margin-left: 5px;
// }
// .feature__popular-title{
//   display: inline-block;
// }
// .popular__video {
//   width: 25%;
//   text-align: center;
//
//   @media (max-width: 600px) {
//       width: 50%;
//   }
//
//   a,h2 {
//     font-family: "brandon-grotesque";
//     font-size: 12px;
//     text-transform: uppercase;
//     font-weight: 700;
//     letter-spacing: 1px;
//     max-width: 90%;
//     margin: 10px auto;
//     color: $text-black;
//     &:hover {
//       color: $primary;
//     }
//   }
//   transition: all 300ms ease;
//   &:hover {
//     cursor: pointer;
//   }
// }
.all-video-archive_desc {
  font-family: "source-code-pro", "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
  margin-bottom: 50px;
  font-size: 16px;

}
.all-video-archive {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 100px;
  @media (max-width: 650px) {
    margin-top: 50px;
  }
  h2 {
    font-family: "Abel", Arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 28px;
    margin-bottom: 0;
  }
  ul {
    padding: 0;
  }
  li {
    padding: 10px 0;
    list-style: none;
    font-family: "source-code-pro", "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
    font-size: 16px;
    letter-spacing: 1px;
    border-bottom: dotted 1px #dcdcdc;
    width: 100%;
    @media (max-width: 1000px) {
        font-size: 20px;
    }
    @media (max-width: 500px) {
        font-size: 16px;
    }
    @media (max-width: 400px) {
      font-size: 14px
    }


    transition: padding 300ms ease;
    color: $text-black;
    &:hover {
      color: $primary;
      padding-left: 20px;
      background-color: #f7f7f7;
      border-left: solid 3px $primary;
    }
  }
}

.single__header--videos {
  margin-bottom: 15px;
  transition: all 300ms;

  h1 {
    padding-bottom: 0;
  }
}
// VIDEO BLOCK
.videos-featured {
  position: relative;
  // background: linear-gradient( to right, rgba(0, 0, 0, 0.65) 30%,rgba(0, 0, 0, 0.05)), url(../img/hk-down.jpg) center center ;
  background-size: cover;
  margin-bottom: 15px;
  @media (max-width: 650px) {
    .container {
      flex-direction: column-reverse;
      background-color: rgba(0,0,0,0.2);
    }
  }
}
.videos-featured__desc {
    @include span-columns(4);
    @include shift(1);
    padding: 100px 0 100px 0;
    color: #fff;

    @media (max-width: 1460px) {
      @include span-columns(5);
      @include shift(0);
    }
    @media (max-width: 1060px) {
      @include span-columns(5.5);
      @include shift(0);
    }
    @media (max-width: 950px) {
      @include span-columns(8.5);
    }
    @media (max-width: 650px) {
      @include span-columns(12);
      @include shift(0);
      padding: 0 0 50px 0;
    }

}
.videos-featured__desc--alt {
  // @include shift(0);
}
.videos-featured__preview {
  position: relative;

  @media (max-width:950px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  @media (max-width: 650px) {
    transform: translate(0,0);
    position: relative;
    top: auto;
    left: auto;
  }

  svg.play {
    transform: translate(-50%,-50%);
    @media (max-width: 650px) {
      height: 100px;
      width: 100px;
      margin-left: -20px;
      transform: translate(0,0);
      position: relative;
      top: auto;
      left: auto;
    }
  }
}
.videos-featured__featured-block {
  padding: 100px 0;
  @include span-columns(5);
  @include shift(1);

  @media (max-width: 1460px) {
    @include span-columns(6);
    @include shift(1);
  }
  @media (max-width: 1460px) {
    @include span-columns(6);
    @include shift(0.5);
  }

  @media (max-width: 950px) {
    @include span-columns(3);

    position: relative;
      img {
        display: none;
      }
  }
  @media (max-width: 650px) {
    @include span-columns(12);
    @include shift(0);
    padding: 0 0 0;
  }

}
.videos-featured__featured-block--alt {
  // @include shift(1);
}

.videos-featured__links {
  @include span-columns(12);
  text-align: center;
  h2 {
    color: #fff;
    width: 100%;
    margin: 20px 0;
    text-shadow: 0 0 5px rgba(0,0,0,0.3);
  }
}
