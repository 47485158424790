// . SINGLE
.single__header {
  padding: 50px 0;
  background-size: cover;
  background-position: center center;
}
.single__header h1 {
  text-align: center;
  padding: 100px 150px 0;
  font-family: "Abel", Arial, sans-serif;;
  text-transform: uppercase;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 2px;
  font-size: 50px;

  @media (max-width: 1050px) {
    padding: 25px 150px 0;
    font-size: 40px;
  }
  @media (max-width: 600px) {
    font-size: 22px;
    padding: 75px 50px 0;
  }
}

.content-end {
    font-family: "source-code-pro", "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
    max-width: 760px;
    margin: 40px auto;
    padding: 0 20px 0 20px;
    font-size: 16px;
    font-weight: 700;

    @media (max-width: 600px) {
      padding: 0 10px 0 10px;
    }
}

.social-links--content-end {
  vertical-align: middle;
  display: inline-block;
  line-height: 50px;

  a {
    display: inline-block;
  }
  .share-title {
    margin: 0;
    display: inline-block;
    vertical-align: top;
  }
  img {
    width: 25px;
    height: 25px;
  }
}

.nav__icon--messanger {
  width: 25px;
  height: 25px;
  transform: translateY(-3px);
}

.more {
  @extend .cf;
}

.more__block {
  @include span-columns(6, block-collapse);
  padding: 100px 30px;
  text-align: center;
  background-size: cover;
  background-position: center center;
  margin-bottom: 15px;
  max-height: 330px;
  overflow: hidden;

  @media (max-width: 600px){
    @include span-columns(12, block-collapse);
    padding: 50px 30px;
  }
}

.more__subtitle {
  color: #fff;
  font-family: "Abel";
  font-style: italic;
  margin-bottom: 0;
  font-size: 22px;
  @media (max-width: 600px){
    font-size: 18px;
  }
}
.more__title {
  color: #fff;
  font-family: "Abel";
  text-transform: uppercase;
  font-size: 30px;
  margin-top: 10px;
  text-shadow: 0 0 3px rgba(0,0,0,0.2);
  font-size: 30px;
  line-height: 1.3;
  @media (max-width: 600px){
    font-size: 26px;
  }
}


.image--full-feature {
  width: 100vw;
  height: 100vh;
  margin: 40px 0;
  position: absolute;
  left: 0;
  background-size: cover;
  background-position: center center;
}
.image-full-feature--spacer {
  width: 100vw;
  height: 100vh;
  margin: 40px 0;
}
.image--full-feature--caption {
  color: #fff;
  position: absolute;
  bottom: 20px;
  right: 20px;
  max-width: 70%;
}

.map-view--galicia {
  max-width: 80%;
  margin: 0 10%;
}
a.linked-image {
  border-bottom: none;
}
