
.full-video-overlay {
  position: absolute;;
  top: -100%;
  left: -100%;
  width: 100%;
  height: 100vh;
  opacity: 0;
  z-index: 0;
  transition: 300ms opacity;

  &.is_open {
    z-index: 100;
    opacity: 1;
    top: 0;
    left: 0;
    position: fixed;
  }
}
.full-video-overlay__fade {
  background: rgba(0,0,0,0.82);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}
.full-video-overlay__focus {
  position: absolute;
  padding: 10vh 10%;
  iframe {
    max-width: 100%;
  }
}
// .video-wrapper {
//   position: relative;
// 	padding-bottom: 56.25%; /* 16:9 */
// 	padding-top: 25px;
// 	height: 0;
// }
// #video-player {
//   position: absolute;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// }

.full-video-overlay__close {
  font-family: "source-code-pro", "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
  color: #fff;
  font-size: 18px;
  position: absolute;
  left: 10%;
  top: 10%;
  transform: translateY(-100%);
  cursor: pointer;
  padding-right: 5px;
  z-index: 110;
  &:hover {
    background: rgba(255,255,255,0.15);
  }
}

// HERO BLOCK
.index-header {
  position: relative;
  padding: 300px 0 300px;
  background: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.35)), url(../img/hike-bg-1.jpg) center center ;
  background-size: cover;
  color: #fff;
  margin-bottom: 15px;

  @media (max-width: 1300px) {
    padding: 225px 0 175px;
  }
  @media (max-width: 1100px) {
    padding: 150px 0;
  }
}

.index-header__title {
  width: 47%;
  float: left;
  @media (max-width: 1300px) {

    h2 {
      font-size: 16px;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
  }
  a:link,
  a:visited {
    color: #fff;
    border-bottom: dotted 1px #fff;
  }
}
.index-header__play {
  @media (max-width: 600px) {
    svg.play {
      top: 0;
      transform: translate(-50%,0%);
    }
  }
}

.index-header__play,
.index-video__video {
  width: 50%;
  float: left;
  position: relative;

  @media (max-width: 600px) {
    width: 100%;
  }
}

.index-video__thumbnail {
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
}
.index-video__container,
.index-video__preview {
  position: relative;
  cursor: pointer;
  svg.play {
    transform: translate(-50%,-50%);
  }
}

//CODE BLOCK
.index-code {
  position: relative;
  background: linear-gradient( to right, rgba(0, 0, 0, 0.6) 30%,rgba(0, 0, 0, 0.1)), url(../img/code-with-sam-bg-1.jpg) center center ;
  background-size: cover;
  margin-bottom: 15px;
}
.cws-logo {
  max-width: 200px;
  margin-left: -15px ;
}

.index-code__featured-block {
  padding: 75px 0;
  @include span-columns(5);

  @media (max-width: 1150px) {
    @include span-columns(12);
    padding: 25px 0;
  }
}
.index-code__featured-block {
  padding: 75px 0;
  @include span-columns(5);

  @media (max-width: 650px) {
    @include span-columns(12);
    padding: 25px 0;
  }
}
.index-code__desc {
    @include span-columns(7);
    padding: 150px 100px 150px 0;
    color: #fff;

    @media (max-width: 1280px) {
      padding: 100px 50px 100px 0;
      font-size: 14px;

    }
    @media (max-width: 1150px) {
      @include span-columns(7);
      padding: 50px 50px 25px 0;
    }
    @media (max-width: 650px) {
      @include span-columns(12);
      padding: 50px 0 25px 0;
      font-size: 14px;
    }

    a:not(.button--corners) {
      color: #fff;
      border-bottom: dotted 1px #fff;
    }
}

// VIDEO BLOCK
.index-video {
  position: relative;
  background: linear-gradient( to right, rgba(0, 0, 0, 0.55) 30%,rgba(0, 0, 0, 0.4)), url(../img/hk-down.jpg) center center ;
  background-size: cover;
  margin-bottom: 15px;
}
.index-video__desc {
    @include span-columns(5);
    padding: 150px 100px 150px 0;
    color: #fff;

    @media (max-width: 1280px) {
      padding: 100px 50px 100px 0;
      font-size: 14px;

    }
    @media (max-width: 1150px) {
      @include span-columns(7);
      padding: 50px 50px 25px 0;
    }
    @media (max-width: 900px) {
      @include span-columns(9);
    }
    @media (max-width: 650px) {
      @include span-columns(12);
      padding: 50px 0 25px 0;
      font-size: 14px;
    }

    a.button--corners {
      @media (max-width: 1400px) {
        margin: 0;
      }
      @media (max-width: 1260px) {
        padding: 8px 18px;
      }
    }
}
.index-video__featured-block {
  padding: 150px 0;
  @include span-columns(7);

  @media (max-width: 1150px) {
    @include span-columns(12);
    padding: 25px 0;
  }
}
.index-video__video {
    @include span-columns(3.5 of 7);
    &:nth-child(2) {
      @include omega;
    }
    @media (max-width: 1150px) {
      @include span-columns(6 of 12);
    }
    @media (max-width: 650px) {
      @include span-columns(12 of 12);
    }
}

.index-video__links {
  @include span-columns(12);
  text-align: center;
  @media (max-width: 650px) {
    margin-bottom: 30px;
  }
  h2 {
    color: #fff;
    width: 100%;
    margin: 20px 0;
    text-shadow: 0 0 5px rgba(0,0,0,0.3);

    @media (max-width: 1400px) {
      font-size: 24px;
      margin: 15px 0;
    }

  }
  a.button--corners {
    @media (max-width: 1400px) {
      margin: 0;
    }
    @media (max-width: 1260px) {
      padding: 8px 18px;
    }
  }
}

.index-photo {
  padding: 100px 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.05),rgba(0, 0, 0, 0.45) 80%), url(../img/hk-lamma.jpg) center center ;
  background-size: cover;
  position: relative;
  margin-bottom: 15px;
  color: #fff;
  @media (max-width: 1280px) {
    padding: 100px 0;
     p {
       font-size: 14px;
     }
  }
  @media (max-width: 1050px) {
    padding: 50px 0;
  }
  @media (max-width: 650px) {
    padding: 15px 0 15px;
  }
}

.index-photo__gallery,
.index-photo__desc {
    @include span-columns(6);
    a {width: 100%;}
}
.index-photo__gallery {
  @media (max-width: 900px) {
    @include span-columns(5);
  }
  @media (max-width: 650px) {
    @include span-columns(12);
    @include omega;
  }
  a.button--corners {
    @media (max-width: 900px) {
      font-size: 12;
      padding: 8px 16px;
    }
    @media (max-width: 900px) {
    }
  }
}
.index-photo__desc {
  @media (max-width: 900px) {
    @include span-columns(7);
  }
  @media (max-width: 650px) {
    @include span-columns(12);
    @include omega;
  }
}

.index-photo__desc {
  padding: 0 0 0 100px;
  h2 {
    line-height: 1;
    @media (max-width: 650px) {
      margin-top: 30px;
    }
  }
  @media (max-width: 1080px) {
    padding: 0 0 0 50px;
     p {
       margin-top: 0;
       font-size: 14px;
     }
  }
  @media (max-width: 650px) {
    padding: 0 0 15px;
  }
}

// .index-photo__caption {
//   font-size: 12px;
//   cursor: pointer;
//   position: absolute;
//   width: 90%;
//   text-align: center;
//   bottom: 10%;
//   left: 50%;
//   transform: translateX(-50%);
//   opacity: 0;
//   transition: all 300ms;
//
//   @media (max-width: 900px) {
//     font-size: 10px;
//   }
// }

.index-photo__gallery__image {
  margin-bottom: 30px;
  box-shadow: 0 0 5px rgba(0,0,0,0.15);
  background-size: cover;
  background-position: center center;
  height: 300px;
  position: relative;

  @include span-columns(2 of 6);
  &:nth-of-type(3n){
    @include omega;
  }
  @media (max-width: 900px) {
    @include span-columns(2.5 of 5);
    margin-bottom: 15px;

    &:nth-of-type(3n){
      margin-right: 3.125%;
    }
    &:nth-of-type(2n){
      @include omega;
    }
  }

  &:hover {
    .index-photo__caption {
      background-color: rgba(200,200,200,0.2);
      opacity: 1;
    }
    a {
      color: #fff;
    }
    .box-corner--tl {
      transform: translate(-5px, -5px);
    }
    .box-corner--tr {
      transform: translate(5px, -5px) rotate(90deg);
    }
    .box-corner--br {
      transform: translate(5px, 5px) rotate(180deg);
    }
    .box-corner--bl {
      transform: translate(-5px, 5px) rotate(-90deg);
    }
  }
}


/// BLOG

.index-articles {
  padding: 100px 0;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 60px 0;
  }

  @media (max-width: 600px) {
    padding: 30px 0;
  }

  h2, h3 {
    font-family: "Abel";
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 28px;
    margin-bottom: 0;
  }
}
.index-article__link {
  padding: 10px 0;
  width: 100%;
  font-family: "source-code-pro", "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
  color: #333;
  font-size: 16px;
  border-bottom: dotted 1px #dcdcdc;

  h2,h3 {
    font-family: "source-code-pro", "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
    color: #333;
    font-size: 16px;
    width: 100%;
    margin: 0;
    text-transform: none;
    font-weight: 300;
    display: inline;
  }
  &:hover {
    color: $primary;
    padding-left: 20px;
    background-color: #f7f7f7;
    border-left: solid 3px $primary;
  }
}
.post-tags {
  float: right;
}
.post-tag {
  border-radius: 3px;
  color: #383838;
  background: #ececec;
  padding: 3px;
  font-size: 0.8rem;
}
.index-article__link--more {
  text-align: right;
  border-right: solid 3px transparent;
  margin-bottom: 50px;
  &:hover {
    color: $primary;
    padding-right: 20px;
    background-color: #f7f7f7;
    border-left: solid 0px $primary;
    border-right: solid 3px $primary;
  }
}

a.boxed-link--dark {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #333;

  .box-corner {
    polyline, polygon, line {
      stroke: #333;
    }
  }
  &:hover,
  &:visited {
    color: #333;
  }
}

// section - location caption
.background-caption {
  height: 40px;
  width: 40px;
  top: auto;
  bottom: 20px;
  right: 20px;
  left: auto;
  opacity: 1;
  transform: none;
  position: absolute;
}
