.single {
  margin: 0 auto;
}
.single--video {
  // max-width: 920px;
  // @include media(650px) {
  //   padding-top: 40px;
  // }
}
.single--legacy {

}
.single__header--subtitle {
  text-align: center;
  color: #fff;
  font-family: "source-code-pro", "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
  a,
  a:visited {
    color: #fff;
    border-bottom: dotted 1px #fff;
    &:hover {
      color: #fff;
      border-color: #fff;
    }
  }
  @media (max-width: 600px) {
    font-size: 12px;
    padding: 25px 50px 0;
  }
}

.content {
  max-width: 760px;
  padding: 60px 20px 60px 20px;
  margin: 0 auto;
  font-family: "source-code-pro", "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
  font-size: 16px;
  overflow: hidden; 
  @media (max-width: 600px) {
    font-size: 14px;
    padding: 30px 10px 30px 10px;
  }

  h1 {

  }
  h2 {
    margin: 40px 0 10px;
    font-size: 30px;
    line-height: 1.1;

    @media (max-width: 600px) {
      font-size: 26px;
    }
  }

  a,
  a:visited {
    color: #111;
    text-decoration: none;
    border-bottom: dashed 1px #111;
    transition: all 0.4s;

    &:hover {
      color: $primary;
      border-color: $primary;
    }
  }
}

.meta {
  text-transform: uppercase;
  font-weight: 700;
}
.meta__author, .meta__category {
  font-size: 14px;
  letter-spacing: 1px;
  color: #7d7d7d;
}

blockquote {
    border: none;
    border-left: solid 5px #ddd;
    padding-left: 20px;
    color: #596c90;
    margin: 30px 20px 30px 0;

    @media (max-width: 600px) {
    }
}
