$text-black: #3C3B3B;
$primary:  #1a6dc3;

* { box-sizing: Border-box }

body {
    font-family: "Abel", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    color: $text-black;
    background-color: #fff;
}

.container {
    max-width: 1600px;

    @media (max-width: 1660px) {
          max-width: 90%;
    }
    @media (max-width: 650px) {
          max-width: 100%;
          padding: 0 15px;
    }
}

img {
    max-width: 100%;
    vertical-align: middle;
}

em {
    color: #9F9F9F;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
    font-family: "Abel", Arial, sans-serif;
}

a,
a:visited {
  color: $primary;
  text-decoration: none;
  transition: all 0.4s;

  &:hover {
    color: darken($primary, 5%);
  }
}
.hr {
  border: none;
  border-top: solid 4px #999;
  margin-bottom: 10px;
}
.hr--small {
  width: 30px;
}

.cf:before,
.cf:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.cf:after {
    clear: both;
}

/**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
.cf {
    *zoom: 1;
}
