.photos__header {
  margin-bottom: 15px;
}
.photos-cta {
  margin-bottom: 15px;
  padding: 5px 0;
  background-color: #438dfc;
  color: #fff;
  text-align: center;
  font-family: "source-code-pro", "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;

  p {
    margin: 0;
  }
}

.photos-featured {
  position: relative;
  padding: 100px 0 100px;
  background: linear-gradient(rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0.5)), url(../img/hike.jpg) bottom center ;
  background-size: cover;
  color: #fff;
  // box-shadow: 0 0 10px rgba(0,0,0,0.2);
  margin-bottom: 15px;
}

.photos-featured__photo {
  @include span-columns(6);
}

.photos-featured__desc {
  @include span-columns(6);
}

.photos-reach {

}
.photo-expand {
  width: 100%;
  text-align: center;
  padding: 30px 0 0;
  cursor: pointer;
  img {
    width: 30px;
    height: 30px;
    margin: 0 10px;
  }
}
.photo-item {
  @include span-columns(4);
  margin-top: -45px;
  margin-bottom: 30px;

  &:nth-of-type(even){
    padding-top: 5%;
  }
  &:nth-of-type(odd){
    padding: 0 1.5%;
  }
  &:nth-of-type(3n) {
    @include omega;
  }
  img {
    max-width: 100%;
  }
  @media (max-width: 600px) {
    margin-top: -15px;
    margin-bottom: 15px;
  }
}
.photo-item--full {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 15px;
  text-align: center;

  &:nth-of-type(odd){
    padding: 0;
  }
  &:nth-of-type(even){
    padding: 0;
  }
  &:nth-of-type(3n) {
    margin-left: auto;
    margin-right: auto;
  }
}

.photo-subtitle {
  margin: 75px 0 90px;
  font-family: "source-code-pro", "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  width: 100%;
  @media (max-width: 600px) {
    margin: 25px 0 25px;
    font-size: 14px;

  }
}


.photo-gallery-end {
  margin: 75px 0 90px;
  font-family: "source-code-pro", "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
  font-size: 17px;
  font-weight: 300;
  text-align: center;
  width: 100%;
  @media (max-width: 600px) {
    margin: 25px 0 25px;
    font-size: 15px;
  }
  a {
    color: #222;
    border-bottom: dotted 1px #222;
  }
}
