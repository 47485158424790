.hero {
  @extend .cf;
  padding: 10vh 0 0;
  @include media(650px) {
      padding: 150px 0 0;
  }
}

.hero__h1 {
  text-transform: uppercase;
  font-weight: 700;
}
.hero__h1,
.hero__h2,
.hero__h3,
.portfolio__introduction h2 {
  font-family: "brandon-grotesque", Helvetica, Arial, sans-serif;
}

.portfolio__introduction p,
.portfolio__grid p {
  font-family: "brandon-grotesque", Helvetica, Arial, sans-serif;
  font-weight: 400;
}


.hero__h1 {
  line-height: 1;
  font-size: 30px;
  margin-top: 20px;
  @include media(650px) {
    margin-top: 10px;
    font-size: 40px;
  }
}
.hero__h2 {
  margin-top: 20px;
  font-size: 16px;
}

.hero__h3 {
  margin-top: 0;
  font-size: 16px;
}

.hero__avatar {
  text-align: left;
  width: 50%;
  margin: 0 auto;

  img {
    border-radius: 100%;
    width: 127px;
  }
  @include media(650px) {
    text-align: right;
    @include span-columns(2);
    @include shift(2.5);
  }
}

.hero__info {
  width: 50%;
  margin: 0 auto;
  @include media(650px) {
    @include shift(0.3);
    @include span-columns(4.7);
  }
}

.hero__social {
  text-align: center;

  @include media(650px) {
    padding: 30px 0;
    @include shift(2);
    @include span-columns(8);
  }
}

.social__link {
  cursor: pointer;
  @include span-columns(2);

  svg {
    width: 80%;
    transform: scale(1.5);
  }
  &:first-of-type{
    @include shift(1);
  }
  &:last-of-type{
    @include omega();
  }
  @include media(650px) {
    margin: 30px 0;

    svg {
      width: auto;
      transform:none;
    }

    &:first-of-type{
      @include shift(0);
    }
    @include span-columns(1.6 of 8);
  }
}

.cta-view-work {
  text-align: center;
  margin-bottom: 125px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  color: #999;
}

.portfolio__introduction {
  @include span-columns(8);

  @include media(1060px) {
    @include span-columns(3);
  }

  p:first-of-type {
    margin-top: 0;
  }
}
.portfolio__grid {
  @include span-columns(12);
  display: flex;
  flex-wrap: wrap;

  @include media(1060px) {
    @include shift(1);
    @include span-columns(8);
    display: flex;
    flex-wrap: wrap;
  }
}
.portfolio__item {
  position: relative;
  background-color: #f7f7f7;
  border: solid 2px #d4d4d4;
  padding: 30px;
  display: flex;
  flex-grow: 1;
  @include span-columns(10 0f 12);
  transition: 200ms;
  margin-bottom: 30px;

   a {
     word-wrap: break-word;
   }
  &:hover {
        box-shadow: 0 0 10px rgba(0,0,0,0.2);
        border-color: #1a6dc3;
        transform: scale(1.03);
        z-index: 30;

        .tag {
          background-color: rgba(26,109,195,0.7);
          color: #fff;
        }
  }
  @include media(650px) {
    @include span-columns(6 0f 12);
    &:nth-of-type(even){
      @include omega();
    }
  }
  @include media(1060px) {
    @include span-columns(4 of 8);
  }
}

.portfolio__heading{
  font-family: "brandon-grotesque";
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
  margin-top: 0;
  position: relative;
  padding-right: 80px;
}
.portfolio__icon {
  float: left;
  margin-right: 15px;

  svg {
    height: 23px;
  }
}

.work-title {
  margin-bottom: 30px;
  font-size: 20px;
}

.portfolio-section {
  @extend .cf;
  padding: 5px 25px;
  margin: 0 auto 15px;

  @include media(650px) {
    padding: 20px 25px;
  }

}

.hero_h1 {
  font-family: "brandon-grotesque";
  font-weight: 700;
  text-transform: uppercase;
}

.tag {
  background-color: #e0e0e0;
  line-height: 1;
  padding: 3px 6px;
  display: inline-block;
  border-radius: 4px;
  margin: 3px;
  color: #333;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  transition: all 300ms;
}

.project-previews {
    height: 130px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    margin-bottom: 20px;
}


.project-preview {
  margin: 0 10px;
  width: 150px;
  display: inline-block;
  filter: grayscale(100%);

  &:hover {
    filter: grayscale(0%);
  }
  span {
    float: left;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 11px;
    margin: 7px 0;
    // letter-spacing: 1px;
  }
}

.item__logo {
  position: absolute;
  height: 30px;
  top: 10px;
  right: 10px;
}
.item__logo--l {

}
@keyframes hue-fade {
  0% {
    filter: hue-rotate(0deg);
  }
  50% {
    filter: hue-rotate(20deg);
  }
  100% {
    filter: hue-rotate(0deg);
  }
}

.faded-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15vh;
  background: linear-gradient(rgba(255,255,255,0.0), #fff);;
}
